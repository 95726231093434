<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:headingtitle="page.headingImageTitle"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>
		<breadcrumbs
			:breadcrumbs="page.breadcrumbs"
			:class="page.headingImage ? '' : 'no-slider'"
			:home="defaults[locale].website.hotelName"
		/>
		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:content-full-width="page.contentFullWidth"
		/>

		<contentblocks v-if="page.contentblocks.length" :items="page.contentblocks" />

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();
</script>
